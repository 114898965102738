<template>
  <div
    :class="'member-page ' + LANGCODE"
    :style="'--aog-currency:\'' + SYSLANG.currency + '\''"
  >
    <div class="header">
      <img class="member-bg" :src="require('@/assets/images/member_bg.jpg')" />
      <div class="base-info">
        <van-image
          round
          width="20vw"
          height="20vw"
          :src="agentInfo.AgentFace ? agentInfo.AgentFace : defaultFace"
          fit="cover"
        />
        <div class="info">
          <span class="name">{{ agentInfo.AgentName }}</span>
          <span class="item">ID: {{ agentInfo.AgentCode }}</span>
          <span class="item" @click="onCopy(agentInfo.InviteCode)"
            >{{ PAGELANG.invitationcode }}: {{ agentInfo.InviteCode }}</span
          >
        </div>
      </div>
    </div>

    <div class="mem-menu mem-menu-01">
      <a class="item" href="#/credit">
        <span class="num price">{{ agentInfo.Balance }}</span>
        <span class="t">{{ PAGELANG.balance }}</span>
      </a>
      <a class="item" href="#/income">
        <span class="num price">{{ agentInfo.Withdrawal }}</span>
        <span class="t">{{ PAGELANG.ketixian }}</span>
      </a>
      <!--<a class="item" href="#/income/toberecorded">
        <span class="num price">{{ agentInfo.ToBeRecorded }}</span>
        <span class="t">{{ PAGELANG.daijiesuan }}</span>
      </a>-->
    </div>

    <ul class="mem-menu-02">
      <li class="item">
        <a href="#/profile">
          <span>
            <i class="iconfont icon-ziliao-01" />
            {{ PAGELANG.个人资料 }}
          </span>
        </a>
      </li>
      <li class="item">
        <a href="#/agentgroup">
          <span>
            <i class="iconfont icon-tuandui2" />
            {{ PAGELANG.我的团队 }}
          </span>
        </a>
      </li>
      <li class="item">
        <a href="#/order">
          <span>
            <i class="iconfont icon-order" />
            {{ PAGELANG.order }}
          </span>
        </a>
      </li>
      <li class="item">
        <a href="#/address">
          <span>
            <i class="iconfont icon-dizhi-02" />
            {{ PAGELANG.dizhi }}
          </span>
        </a>
      </li>
      <li class="item">
        <a href="#/credit">
          <span>
            <i class="iconfont icon-licai" />
            {{ PAGELANG.daikuanzhanghu }}
          </span>
        </a>
      </li>
      <li class="item">
        <a href="#/income">
          <span>
            <i class="iconfont icon-shouyi" />
            {{ PAGELANG.shouyizhanghu }}
          </span>
        </a>
      </li>
      <li v-if="agentInfo.ExpireDTimeFormat" class="item">
        <a @click="viewPDF">
          <span>
            <i class="iconfont icon-zhengshu1" />
            {{ PAGELANG.zhengshu }}
          </span>
          <span class="expiration"
            >{{ agentInfo.ExpireDTimeFormat }} {{ PAGELANG.expiration }}</span
          >
        </a>
      </li>
      <li class="item">
        <a href="#/credit/recharge">
          <span>
            <i class="iconfont icon-chongzhi" />
            {{ PAGELANG.chonghzi }}
          </span>
        </a>
      </li>
    </ul>

    <div style="height: 120px"></div>

    <a class="btn-exit" @click="onLogout">{{ PAGELANG.退出 }}</a>
  </div>
</template>

<script>
import { getCookie, setCookie } from "../util/index.js";
import {
  showConfirmDialog,
  showLoadingToast,
  showToast,
  closeToast,
} from "vant";
export default {
  data() {
    return {
      balance: 0, // 货款余额
      withdrawal: 0, // 收益金额
      toberecorded: 0, // 待入账收益
      agentInfo: {},
      defaultFace: require("@/assets/images/default_face.png"),
    };
  },
  beforeCreate() {
    if (!getCookie("agentloginid")) {
      this.$router.replace({
        path: "/login",
      });
      return;
    }
  },
  created() {
    if (!getCookie("agentloginid")) {
      return;
    }

    this.PAGELANG = this.LANG.pages.my;
    this.SYSLANG = this.LANG.system;
    this.LANGCODE = this.langcode;
    this.UPLOADBASE = this.uploadbase;

    showLoadingToast({
      message: this.SYSLANG.loading,
      duration: 0,
      forbidClick: true,
    });

    this.axios.get(this.actions.member).then(({ data }) => {
      closeToast();
      let { code, msg, agentInfo } = data;
      if (code == "SUCCESS") {
        this.agentInfo = agentInfo;
      } else {
        showToast({
          message: code == "NOTLOGIN" ? this.SYSLANG[msg] : this.GOODSLANG[msg],
          forbidClick: true,
          onClose: () => {
            if (code == "NOTLOGIN") {
              this.$router.replace({
                path: "/login",
              });
            }
          },
        });
      }
    });
  },
  methods: {
    onCopy(content) {
      let oInput = document.createElement("input");
      oInput.value = content;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      console.log(oInput.value);
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.remove();
      showToast(this.SYSLANG.copysuccess);
    },
    onLogout() {
      showConfirmDialog({
        title: this.SYSLANG.dialogtitle,
        message: this.PAGELANG.您确定退出登录吗,
        confirmButtonText: this.SYSLANG.queding,
        cancelButtonText: this.SYSLANG.quxiao,
      })
        .then(() => {
          //退出登录
          setCookie("agentloginid", "");
          setCookie("agentlogintoken", "");
          this.$router.push("/login");
        })
        .catch(() => {});
    },
    viewPDF() {
      /*this.pagedata.pdfurl = this.UPLOADBASE + this.agentInfo.CertUrl;
      this.$router.push({
        path: "/viewpdf",
      });*/
      window.location.href = this.UPLOADBASE + this.agentInfo.CertUrl;
    },
  },
};
</script>


<style src="../assets/css/my.css" scoped>
</style>